@import '../../mixins';

.category-card {
  position: relative;

  width: rem(460);
  height: rem(700);
  display: flex;

  overflow: hidden;

  @include mediaBigDesktop {
    width: big(460);
    height: big(700);
  }

  @include mediaLaptop {
    width: rem(345);
    height: rem(525);
  }

  @include mediaTablet {
    width: rem(329);
    height: rem(501);
  }

  @include mediaMobile {
    width: rem(280);
    height: rem(326);
  }

  @include hover {
    & .category-card {
      &__image {
        & img {
          transform: scale(1.1);
        }
      }
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 100%;

    background-color: var(--bg-light-extra);

    pointer-events: none;
    user-select: none;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;

      width: 100%;
      height: 100%;

      background-image: linear-gradient(0deg, var(--text-dark-secondary) 0%, rgba(0, 0, 0, 0.00) 45.14%);
    }

    & img,
    & picture {
      position: relative;
      z-index: 1;

      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center;

      transition: transform var(--animation-timing-medium) var(--cubic-bezier);
    }
  }

  &__content {
    position: relative;
    z-index: 2;

    padding: 0 rem(40) rem(35);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    gap: rem(10);

    color: var(--text-light-primary);
    text-decoration: none;

    cursor: pointer;

    @include mediaBigDesktop {
      padding: 0 big(40) big(35);
      gap: big(10);
    }

    @include mediaLaptop {
      padding: 0 rem(30) rem(30);
    }

    @include mediaTablet {
      padding: 0 rem(25) rem(25);
    }

    @include mediaMobile {
      padding: 0 rem(20) rem(20);
    }
  }

  &__name {
    color: var(--text-light-primary);
    
    @include mediaMobile {
        font: var(--font-body-S);
    }
  }

  &__text {
    color: var(--text-light-secondary);
    font: var(--font-body-S);
    
    @include mediaDesktop {
      font: var(--font-body-M);
    }
    
    @include mediaMobile {
      display: none;
    }
  }
}
